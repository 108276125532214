import "./LoadingPages.css";

const LoadingPages = () => {
  return (
    <div>
      <div id="spinner" class="elonatech-container">
        <div class="elonatech-loading"></div>
      </div>
    </div>
  );
};

export default LoadingPages;
